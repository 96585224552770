import React from 'react';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import ClipboardCopy from '../../widgets/clipboard_copy';
import UpdateEmailModal from './../update_email';
import AddDailyLimitModal from '../add_daily_limit';
import UpdateDailyLimitModal from '../update_daily_limit'
import RemoveDailyLimit from '../remove_daily_limit'
import AllowedDailyLimitModal from '../allowed_daily_limit'
import AddBonusModel from '../add_bonus';
import ResetBonusModel from '../reset_bonus';
import UpdateMemberStatus from '../update_member_status';
import UpdateTimezoneModal from '../update_timezone';
import UpdateRateLimitModal from '../update_api_rate_limit';
import RemoveRateLimitModel from '../remove_api_rate_limit';
import * as Helpers from '../../../helpers/helper'
import * as IndividualUserConstants from '../helpers/constant'
import ClickToCopy from '../../widgets/copy_onclick';
import * as Constants from '../../../helpers/constants'
import CustomOverlayTrigger from '../../widgets/overlay_trigger'

import './../../../static/css/profile.css'


export const emailFormatter = ({ client, value, userData, user_id, getUserInfo }) => {
  return (
    <div className="d-flex flex-wrap">
      <div className='d-flex'>
        <CustomOverlayTrigger
          content={value}
          placement='top'
        >
          <span className="d-flex align-items-center">
            <span className="me-2">{Helpers.truncateEmail(value, 20)}</span>
            <ClipboardCopy textToCopy={value} />
          </span>
        </CustomOverlayTrigger>
        <div className="px-2">
          {_.includes(IndividualUserConstants.BASIC_INFO[client].SHOW_BUTTONS, 'edit_email') ? (
            <UpdateEmailModal
              client={client}
              couserid={user_id}
              getUserInfo={getUserInfo}
              data={userData}
            />
          ) : null}
        </div>
      </div>
    </div>

  )
}
export const phoneFormatter = ({ value }) => {
  return (
    <div className='d-flex'>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </div>
  )
}

export const UserRole = ({ value }) => {
  return (
    <p>{value === 'analyst' ? 'Executive' : Helpers.capitalizeFirstLetter(value)}</p>
  )
}

export const remainingCreditsFormatter = ({ client, userData, getUserInfo }) => {
  return (
    <div className="d-flex gap-2 remaining-credits">
      {userData.user_type === 'individual' ? (
        <AddBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          email={userData.email}
          userData={userData}
        />
      ) : null}
      {userData.user_type === 'individual' ? (
        <ResetBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          userData={userData}
        />
      ) : null}
    </div>
  )
}

export const jobTitleFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.job_title
        ? Helpers.capitalizeFirstLetter(additionalData.job_title)
        : '-'}
    </>
  )
}

export const industryFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.industry
        ? Helpers.capitalizeFirstLetter(additionalData.industry)
        : '-'}
    </>
  )
}

export const signupFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData?.user_profile_additional_info && additionalData.user_profile_additional_info.signup_source && additionalData.user_profile_additional_info.signup_method
        ? `${Helpers.capitalizeFirstLetter(additionalData.user_profile_additional_info.signup_source)} / ${Helpers.capitalizeFirstLetter(additionalData.user_profile_additional_info.signup_method)}`
        : 'Direct / Email'}
    </>
  )
}

export const companyNameFormatter = ({ additionalData }) => {
  return (
    <>s
      {additionalData && additionalData.organization_name
        ? Helpers.capitalizeFirstLetter(additionalData.organization_name)
        : '-'}
    </>
  )
}

export const userIdFormatter = ({ value }) => {
  return (
    <div className='text-break'>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </div>
  )
}

export const orgIdFormatter = ({ value, userData, client }) => {
  return (
    <div className='d-flex'>
      <div>
        {value ? (
          <ClickToCopy text={value} />
        ) : '-'}
      </div>
      <div className='px-1'>
        {userData.user_type === 'organization' ? (
          <>
            <Link
              to={`/${client}/organization_info?org_id=${userData.org_id}`}
              title="org info"
              className='ms-1'
            >
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className='text-primary'
              />
            </Link>
          </>
        ) : null}
      </div>
    </div>
  )
}

export const activatedOnFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={moment(value).format('DD-MMM-YYYY @ h:mm:ss A')} />
      ) : 'Yet to Activate'}
    </>
  )
}

export const memberStatusFormatter = ({ client, value, userData, user_id, getUserInfo }) => {
  return (
    <>
      <div className={userData.account_status === 'live' ? 'text-success' : 'text-danger'}>
        {Helpers.capitalizeFirstLetter(value) ?? '-'}
      </div>
      {userData.user_type == 'organization' && userData.user_role !== 'owner' ? (
        <UpdateMemberStatus
          couserid={user_id}
          getUserInfo={getUserInfo}
          userData={userData}
          client={client}
        />
      ) : null}
    </>
  )
}

export const accountStatusFormatter = ({ value, userData }) => {
  return (
    <div className={userData.status === 'active' ? 'text-success' : 'text-danger'}>
      {Helpers.capitalizeFirstLetter(value) ?? '-'}
    </div>
  )
}

export const radarFormatter = ({ userData, isChecked, handleToggleChange }) => {
  return (
    <>
      {userData && userData.user_role === 'owner' ? (
        <Toggle
          checked={isChecked}
          onChange={handleToggleChange}
          disabled={
            userData.status === 'active' &&
              userData.account_status === 'live' &&
              userData.activated_on
              ? false
              : true
          }
        />
      ) : (
        '-'
      )}
    </>
  )
}

export const timezoneFormatter = ({ value, userData, user_id, getUserInfo, client }) => {
  return (
    <div className='d-flex'>
      <div>
        {value ?? '-'}
      </div>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='mx-2'>
            <UpdateTimezoneModal
              couserid={user_id}
              getUserInfo={getUserInfo}
              data={userData}
              client={client}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const totalAmountPaidFormatter = ({ additionalData }) => {
  if (additionalData && !_.isEmpty(additionalData.payment_info))
    return Number(additionalData.payment_info.total).toLocaleString()
  else
    return '-'
}
export const lastPurchasedOnFormatter = ({ additionalData }) => {
  if (additionalData && !_.isEmpty(additionalData.payment_info)) {
    const timestamp = additionalData.payment_info.last_purchased_on;
    const formattedDate = moment(timestamp).format('DD-MMM-YYYY @ h:mm:ss a');
    const humanReadable = moment(timestamp).fromNow();
    const capitalizedHumanReadable = _.capitalize(humanReadable);

    return (
      <span>
        <CustomOverlayTrigger
          placement='top'
          content={formattedDate}
        >
          <span>{capitalizedHumanReadable}</span>
        </CustomOverlayTrigger>
      </span>
    );
  } else {
    return '-';
  }
};




export const totalPaymentCountFormatter = ({ additionalData }) => {
  if (additionalData && !_.isEmpty(additionalData.payment_info))
    return Number(additionalData.payment_info.count)
  else
    return '-'
}

// credits and limits tab sections formatters

export const totalRemainingCreditsFormatter = ({ value }) => {
  return (
    <>
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
    </>
  )
}

export const payAsYouGoCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const subscriptionCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const recurCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const apiRateLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='d-flex'>
            <div className='mx-2'>
              <UpdateRateLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
            <div>
              <RemoveRateLimitModel
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantEmailVerifyFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const instantEmailFinderFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const dailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )

}

export const remainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex gap-2'>
          <p>{(userData.credits.available_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' && userData.limits.allowed_daily_limit ? (
            <div>
              <UpdateDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export const allowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex'>
          <p>{(userData.limits.allowed_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' ? (
            <div className='mx-2'>
              <AllowedDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}
export const nextCreditsAllocationDate = ({ additionalData }) => {
  let creditAllocationDate = '-';

  if (_.isEmpty(additionalData?.subscription_details)) {
    return creditAllocationDate;
  }

  let planSubs = _.find(additionalData.subscription_details, { product_type: 'plan' });

  if (!planSubs || !planSubs.credits_renewal_date) {
    return creditAllocationDate;
  }

  let planRenewal = { count: planSubs.product_renewal?.count, unit: planSubs.product_renewal?.unit };
  let creditRenewal = { count: planSubs.credits_renewal?.count, unit: planSubs.credits_renewal?.unit };

  if (planSubs.status !== 'active' && _.isEqual(planRenewal, creditRenewal)) {
    return creditAllocationDate;
  }

  if (!planSubs.credits_renewal_date)
    return creditAllocationDate

  creditAllocationDate = moment(planSubs.credits_renewal_date).format('Do MMMM YYYY, h:mm:ss A')
  const humanReadable = moment(planSubs.credits_renewal_date).fromNow()


  let subscriptionColor = planSubs.status !== 'active' ? 'text-danger' : 'text-success';

  return (
    <span>
      <CustomOverlayTrigger
        placement="top"
        content={<span>{creditAllocationDate}</span>}
      >
        <span className={subscriptionColor}>{_.capitalize(humanReadable)}</span>
      </CustomOverlayTrigger>
    </span>
  );
}

export const planFormatter = ({ additionalData, userData }) => {

  let productName = '-'
  if (userData.user_role !== Constants.DISPLAY_USER_ROLES.owner.value)
    return productName

  const subscriptionDetails = additionalData.subscription_details
  const planSubs = _.find(subscriptionDetails, { product_type: 'plan' })

  if (planSubs && !_.isEmpty(planSubs.product_name)) {
    productName = planSubs.product_name


    let subscriptionColor = planSubs.status !== 'active' ? 'text-danger' : 'text-success';

    return (
      <div className={`truncate-text ${subscriptionColor}`}>
        <CustomOverlayTrigger
          placement='top'
          content={<span>{productName}</span>}
        >
          <span>{productName}</span>
        </CustomOverlayTrigger>
      </div>
    );
  }

  return '-';
};

export const nextBillingDate = ({ additionalData }) => {
  let formattedDate = '-';

  if (_.isEmpty(additionalData.subscription_details))
    return formattedDate

  const plan = _.find(additionalData.subscription_details, { product_type: 'plan' })

  if (plan.status !== 'active' || !plan.end_time) {
    return formattedDate;
  }

  formattedDate = moment(plan.end_time).format('Do MMMM YYYY, h:mm:ss A')
  const humanReadable = moment(plan.end_time).fromNow()


  let subscriptionColor = plan.status !== 'active' ? 'text-danger' : 'text-success';

  return (
    <span>
      <CustomOverlayTrigger
        placement='top'
        content={<span>{formattedDate}</span>}
      >
        <span className={subscriptionColor}>{_.capitalize(humanReadable)}</span>
      </CustomOverlayTrigger>
    </span>
  );
}

export const lowCreditThreshold = ({ userData }) => {
  let data = '-'
  let thresholdSign = ''
  if (userData.settings?.low_credit_balance) {
    data = userData.settings?.low_credit_balance.min_threshold
    if (data >= userData.credits?.total) {
      thresholdSign = 'text-danger'
    }
  }
  return (
    userData.user_type === 'organization' && userData.user_role === 'owner' || userData.user_type === 'individual'
      ? <span className={thresholdSign}>{data !== '-' ? data.toLocaleString() : '-'}</span>
      : '-'
  )
}

export const billableFormatter = (userData) => {
  const subscription = userData.userData.subscription
  const planSubs = _.find(subscription, { product_type: 'plan' })


  if (!planSubs.price.total_price || !planSubs.product_renewal.unit) {
    return '-';
  }

  const duration = Helpers.getRenewalUnit(planSubs.product_renewal)
  const currency = planSubs.price.currency
  const amount = planSubs.price.total_price.toLocaleString(Constants.CURRENCY[currency.toUpperCase()].LOCALE);
  const currencySymbol = Constants.CURRENCY[currency].symbol
  const status = (planSubs.status || null)

  if (!status) {
    return '-';
  }

  const billable = `${currencySymbol}${amount}/${duration}`;
  const subscriptionColor = status !== 'active' ? 'text-danger' : 'text-success';

  return (
    <span className={subscriptionColor}>
      {billable}
    </span>
  )
}

// cop formatterrs

export const copDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_verify_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_verify_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )
}

export const copRemainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.credits.available_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <UpdateDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const copAllowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.limits.allowed_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <AllowedDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantPhoneNumberValidatorFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const copTotalAmountPaidFormatter = ({ value }) => {
  return (
    <>
      {value ? Number(value).toLocaleString() : '-'}
    </>
  )
}

export const copLastPurchasedOnFormatter = ({ value }) => {
  if (value) {
    const formattedDate = moment(value).format('DD-MMM-YYYY @ h:mm:ss a');
    const humanReadable = moment(value).fromNow();
    const capitalizedHumanReadable = _.capitalize(humanReadable);

    return (
      <span>
        <CustomOverlayTrigger
          placement='top'
          content={<span>{formattedDate}</span>}
        >
          <span>{capitalizedHumanReadable}</span>
        </CustomOverlayTrigger>
      </span>
    );
  }

  return '-';
};



export const copTotalPaymentCountFormatter = ({ value }) => {
  return (
    <>
      {value ? value : '-'}
    </>
  )
}

// receipt table formatter

export const receiptCreditFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const coReceiptBillingFormatter = ({ rowData, value }) => {
  let billing = '-'

  if (rowData.type === 'paid')
    if (value === null)
      billing = 'One-Time'
    else
      billing = `${value.count} ${value.unit}`

  return (
    <>{billing}</>
  )
}

export const receiptBillingFormatter = ({ value }) => {
  return (
    <>
      {value ?
        (value === 30 ? 'Monthly' : 'Annually') : '-'}
    </>
  )
}

export const receiptPaymentFormatter = ({ value }) => {
  return (
    <>
      {value}
    </>
  )
}

export const receiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const receiptAmountFormatter = ({ rowData, value }) => {
  let formattedAmount = value.toLocaleString(Constants.CURRENCY.USD.locale)
  let val = formattedAmount
  let toolTipContent = <></>

  if (rowData.type === 'paid') {
    let basePrice = rowData.amount_details.base_price.toLocaleString(Constants.CURRENCY.USD.locale)

    if (rowData.amount_details.currency !== Constants.CURRENCY.USD.name) {
      let formattedCurrencyAmount = rowData.amount_details.total_price
        .toLocaleString(Constants.CURRENCY[rowData.amount_details.currency].locale)

      val = formattedCurrencyAmount

      toolTipContent = (
        <div className='d-flex flex-column text-start'>
          <span>
            <small>{'Actual price: '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{basePrice}</span>
          </span>
          <span>
            <small>{'USD equivalent (Incl. tax): '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{formattedAmount}</span>
          </span>
        </div>
      )
    } else {

      toolTipContent = (
        <div className='d-flex flex-column text-start'>
          <span>
            <small>{'Actual price: '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{basePrice}</span>
          </span>
        </div>
      )
    }

    return (
      <>
        <CustomOverlayTrigger
          content={toolTipContent}
          placement={'bottom'}
        >
          <div>
            {rowData.amount_details.currency_symbol}
            {val}
          </div>
        </CustomOverlayTrigger>
      </>
    )

  }


  return (
    <>{val}</>
  )
}

export const receiptCouponFormatter = ({ rowData, value }) => {
  let coupon = '-'

  if (value && value.coupon) {
    let couponVal = 0
    let toolTipContent = <></>

    if (value.coupon_type === 'discount') {
      let formattedAmount = value.discount_price.toLocaleString(Constants.CURRENCY.USD.locale)
      let formattedCurrencyAmount
      couponVal = `${Constants.CURRENCY.USD.symbol} ${formattedAmount}`

      if (rowData.amount_details.currency !== Constants.CURRENCY.USD.name) {
        formattedCurrencyAmount = rowData.amount_details.discount_price
          .toLocaleString(Constants.CURRENCY[rowData.amount_details.currency].locale)

        couponVal = `${Constants.CURRENCY[rowData.amount_details.currency].symbol} ${formattedCurrencyAmount}`
        toolTipContent = (
          <div className='d-flex flex-column text-start'>
            <span>
              <small>{'USD Equivalent: '}</small>
              <span>{Constants.CURRENCY.USD.symbol}{formattedAmount}</span>
            </span>
          </div>
        )
      }
    }

    if (value.coupon_type === 'bonus') {
      couponVal = `${value.bonus_value.toLocaleString('en-US')} Credits`
    }

    coupon =
      <div className='d-flex flex-column'>
        <Link to={'/clearout/coupons/' + value.coupon}>
          {value.coupon}
        </Link>
        <span className='text-muted text-uppercase'>
          <small>
            <CustomOverlayTrigger
              content={toolTipContent}
              placement={'bottom'}
            >
              <div>
                {couponVal}
              </div>
            </CustomOverlayTrigger>
          </small>
        </span>
      </div>
  }

  return (
    <>
      {coupon}
    </>
  )
}

export const receiptPurchasedFormatter = ({ value }) => {
  return (
    <small>
      {moment(value).format('DD MMM YY, hh:mm:ss A z')}
    </small>
  )
}

export const receiptTypeFormatter = ({ value }) => {
  return (
    <>
      {Helpers.capitalizeFirstLetter(value)}
    </>
  )
}

export const receiptDescriptionFormatter = ({ rowData, value }) => {
  let val = '-'
  if (value)
    val = value

  if (rowData.type === 'paid')
    val = (
      <div className='d-flex flex-column'>
        <span>{rowData.product_details.display_name}</span>
        <span className='text-muted text-uppercase'>
          <small>
            Payment Gateway: {_.startCase(rowData.account_name || rowData.gateway)}
          </small>
        </span>
      </div>
    )

  return (<>{val}</>)
}

export const receiptPlanIdFormatter = ({ value }) => {
  return (
    <>
      <b><small>{value ? value : '-'}</small></b>
    </>
  )
}

export const receiptPayeeInfoFormatter = ({ value, rowData }) => {
  const getPayeeInfo = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.payer.name.given_name + ' ' + invoice.payment_details.result.payer.name.surname}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.payer.email_address}&nbsp; &nbsp;
            <ClipboardCopy textToCopy={invoice.payment_details.result.payer.email_address} />
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeInfo(rowData) : '-'}
    </>
  )
}

export const coReceiptPayeeInfoFormatter = ({ value }) => {
  let payeeInfo = '-'

  if (value && value.gateway && value.gateway.email_address) {
    payeeInfo = (
      <>
        <span>{value.gateway.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.email_address} />
        </span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.user_id}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.user_id} />
        </span>
      </>
    )
  } else if (value && value.co && value.co.email_address) {
    payeeInfo = (
      <>
        <span>{value.co.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.co.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.co.email_address} />
        </span>
      </>
    )
  }

  return (
    <>{payeeInfo}</>
  )
}

export const coReceiptPayeeAddressFormatter = ({ value }) => {
  let payeeAddress = '-'

  if (value && value.gateway && value.gateway.billing_address.country_code) {
    payeeAddress = (
      <>
        <span>
          {
            value.gateway.billing_address.address_line_1
              ? value.gateway.billing_address.address_line_1 + ', '
              : null
          }
          {
            value.gateway.billing_address.address_line_2
              ? value.gateway.billing_address.address_line_2 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.admin_area_2
              ? value.gateway.billing_address.admin_area_2 + ', '
              : null
          }
          {
            value.gateway.billing_address.admin_area_1
              ? value.gateway.billing_address.admin_area_1 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.postal_code
              ? value.gateway.billing_address.postal_code + ' - '
              : null
          }
          {
            value.gateway.billing_address.country_code
              ? value.gateway.billing_address.country_code
              : null
          }
        </span>
      </>
    )
  } else if (_.get(value, 'co.billing_address.country.value', null)) {
    payeeAddress = (
      <>
        <span>
          {
            value.co.billing_address.line1
              ? value.co.billing_address.line1 + ', '
              : null
          }
          {
            value.co.billing_address.line2
              ? value.co.billing_address.line2 + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.city
              ? value.co.billing_address.city + ', '
              : null
          }
          {
            value.co.billing_address.state
              ? value.co.billing_address.state + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.postal_code
              ? value.co.billing_address.postal_code + ' - '
              : null
          }
          {
            value.co.billing_address.country.value
              ? value.co.billing_address.country.value
              : null
          }
        </span>
      </>
    )
  }

  return (
    <div className='d-flex flex-column'>
      {payeeAddress}
    </div>
  )
}

export const receiptPayeeAddressFormatter = ({ value, rowData }) => {
  const getPayeeAddress = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.purchase_units[0].shipping.address.address_line_1}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_1 + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_2}
          </span>
          <span>
            {invoice.payment_details.result.purchase_units[0].shipping.address.country_code + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.postal_code}
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeAddress(rowData) : '-'}
    </>
  )
}

export const invoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, rowData, client }) => {
  if (rowData.type !== 'paid')
    return null

  const apiInvoicePath = IndividualUserConstants.CO_INVOICE_DOWNLOAD_ENDPOINT(client)
  const apiReceiptPath = IndividualUserConstants.CO_RECEIPT_DOWNLOAD_ENDPOINT(client)
  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          APP_API_URL +
          apiInvoicePath +
          'invoice_id=' + value +
          '&auth_token=' + jwtToken +
          '&co_user_id=' + userData.user_id
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          APP_API_URL + apiReceiptPath +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData.user_id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}

//cop receipt table formatter
export const copReceiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const copReceiptCouponFormatter = ({ rowData, value }) => {
  let coupon = '-'

  if (value && value.coupon) {
    let couponVal = 0
    let toolTipContent = <></>

    if (value.coupon_type === 'discount') {
      let formattedAmount = value.discount_price.toLocaleString(Constants.CURRENCY.USD.locale)
      let formattedCurrencyAmount
      couponVal = `${Constants.CURRENCY.USD.symbol} ${formattedAmount}`

      if (rowData.amount_details.currency !== Constants.CURRENCY.USD.name) {
        formattedCurrencyAmount = rowData.amount_details.discount_price
          .toLocaleString(Constants.CURRENCY[rowData.amount_details.currency].locale)

        couponVal = `${Constants.CURRENCY[rowData.amount_details.currency].symbol} ${formattedCurrencyAmount}`
        toolTipContent = (
          <div className='d-flex flex-column text-start'>
            <span>
              <small>{'USD Equivalent: '}</small>
              <span>{Constants.CURRENCY.USD.symbol}{formattedAmount}</span>
            </span>
          </div>
        )
      }
    }
    if (value.coupon_type === 'bonus') {
      couponVal = `${value.bonus_value.toLocaleString('en-US')} Credits`
    }

    coupon =
      <div className='d-flex flex-column'>
        <Link to={'/clearoutphone/coupons/' + value.coupon}>
          {value.coupon}
        </Link>
        <span className='text-muted text-uppercase'>
          <small>
            <CustomOverlayTrigger
              content={toolTipContent}
              placement={'bottom'}
            >
              <span>
                {couponVal}
              </span>
            </CustomOverlayTrigger>
          </small>
        </span>
      </div>
  }

  return (
    <>
      {coupon}
    </>
  )
}

//need to discuss
export const copInvoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, userName, id, rowData }) => {
  if (rowData.type !== 'paid')
    return null

  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_INVOICE_DOWNLOAD_ENDPOINT +
          'invoice_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_RECEIPT_DOWNLOAD_ENDPOINT +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}
















