import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { useSelector } from 'react-redux';

import showToastNotification from '../../../../widgets/toastify'
import UserSubscriptionsTable from '../../../../tables/subscription_history/user_subscriptions'
import { ApiHelper } from '../../../../../helpers/apihelper'
import ApiService from '../../../../../helpers/apiservice';

import * as Constants from '../../../../../helpers/constants'

const initialState = {
  userSubscriptions: []
}

const UserSubscriptions = ({ client, userData }) => {

  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.name);

  const getUsersSubscriptions = useCallback(() => {
    let requestBody = {
      co_user_id: userData.user_id,
    }
    let body = _.pick(requestBody, ApiHelper(client, 'USER_SUBSCRIPTIONS').API_BODY)
    ApiHelper(client, 'USER_SUBSCRIPTIONS').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            userSubscriptions: response.data
          }))
        } else {
          let message = response.message || 'Something went wrong'
          showToastNotification({
            message,
            isSuccess: false,
          });
        }
      })
      .catch((err) => {
        let message = err.message || 'Something went wrong'
        showToastNotification({
          message,
          isSuccess: false,
        });
      })
  }, [userData.user_id, client])

  //cop subs
  const getCopUsersSubscriptions = useCallback(()=>{
    if(client !== 'clearoutphone') return;
    let body = {
      cop_user_id: userData.user_id,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
    }
    ApiService.clearoutPhoneUserSubsList(body)
      .then((response) => {
        setState(prevState => ({
          ...prevState,
          userSubscriptions: response.data
        }))
      }
      )
      .catch((err) => {
        console.log(err)
      })
  }, [userData.user_id, client, id, userName])

  // mount
  useEffect(() => {
    if(client !== 'clearoutphone')
      getUsersSubscriptions()
  }, [getUsersSubscriptions, client])

  useEffect(() => {
    if(client === 'clearoutphone')
      getCopUsersSubscriptions()
  }, [getCopUsersSubscriptions, client])

  const columns = [
    {
      label: 'Plan',
      renderCell: (userProduct) => {
        return (
          <div className='d-flex flex-column'>
            <span className='fw-bold text-wrap'>{_.startCase(userProduct.product_name)}</span>
            <span>{'Quantity: '}&nbsp;{userProduct.purchase_details.quantity}</span>
            <span>{'Subs Id: '}&nbsp;{userProduct._id}</span>
            <span>{'Plan Id: '}&nbsp;{userProduct.product_id}</span>
            <span>{'Renewal: '}{userProduct.renewal_cycle.count}&nbsp;{userProduct.renewal_cycle.unit}</span>
            <span className={classNames('text-capitalize', {
              'text-success': userProduct.status === 'active',
              'text-danger': userProduct.status !== 'active'
            })}
            >
              {userProduct.status}
            </span>
            {
              ['cancelled', 'expired'].includes(userProduct.status) &&
                userProduct.cancel &&
                userProduct.cancel.reason
                ?
                <>
                  <span className='text-wrap'>{'Cancelled On: '}{moment(userProduct.cancel.cancelled_at).format('Do MMMM YYYY, h:mm:ss A')}</span>
                  <span className='text-wrap'>{'Reason: '}{userProduct.cancel.reason}</span>
                </>
                : null
            }
          </div>
        )
      },
    },
    {
      label: 'Info',
      renderCell: (userProduct) => {
        return (
          <div className='d-flex flex-column'>
            <span>{'Starts On: '}{moment(userProduct.start_time).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Ends On: '}{moment(userProduct.end_time).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Created On: '}{moment(userProduct.created_on).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Modified On: '}{moment(userProduct.modified_on).format('Do MMMM YYYY, h:mm:ss A')}</span>
          </div>
        )
      },
    },
    {
      label: 'Gateway',
      renderCell: (userProduct) => {
        let subsEmail = ''
        let gatewayAccount = ''
        let gatewaySubsId = ''
        let gatewayProductId = ''

        if (userProduct.purchase_details.paypal) {
          gatewayAccount = userProduct.purchase_details.paypal.account
          subsEmail = _.get(userProduct.purchase_details, 'capture_response.subscriber.email_address', null)

          if (!subsEmail)
            subsEmail = _.get(userProduct.purchase_details, 'cancel_response.subscriber.email_address', null)

          if (!subsEmail)
            subsEmail = _.get(userProduct.purchase_details, 'suspend_response.subscriber.email_address', null)

          let subs_id = userProduct.purchase_details.paypal.subs_id
          gatewayProductId = userProduct.purchase_details.paypal.product_id

          gatewaySubsId =
            <a
              target='_blank'
              rel='noreferrer'
              href={`${PAYPAL_DASHBOARD_URL}`}
            >
              {subs_id}
            </a>
        }

        if (userProduct.purchase_details.stripe) {
          gatewayAccount = userProduct.purchase_details.stripe.account
          subsEmail = userData.email
          let subs_id = userProduct.purchase_details.stripe.subs_id
          let product_id = userProduct.purchase_details.stripe.product_id

          gatewaySubsId =
            <a
              target='_blank'
              rel='noreferrer'
              href={`${STRIPE_DASHBOARD_URL}/subscriptions/${subs_id}`}
            >
              {subs_id}
            </a>

          gatewayProductId =
            <a
              target='_blank'
              rel='noreferrer'
              href={`${STRIPE_DASHBOARD_URL}/products/${product_id}`}
            >
              {product_id}
            </a>
        }

        return (
          <div className='d-flex flex-column'>
            <span>{'Account: '}<span className='fw-bold'>{_.startCase(gatewayAccount)}</span></span>
            <span>{'Subs Id: '}<span className='fw-bold'>{gatewaySubsId}</span></span>
            <span>{'Plan Id: '}<span className='fw-bold'>{gatewayProductId}</span></span>
            <span>{'Currency: '}<span className='fw-bold'>{userProduct.purchase_details.amount_details.currency}</span></span>
            <span>{'Subscriber Email: '}<span className='fw-bold'>{subsEmail}</span></span>
          </div>
        )
      },
    },
    {
      label: 'Next Dates',
      renderCell: (userProduct) => {
        const currentDateTime = new Date();
        const isEndTimeGreater = userProduct.end_time ? new Date(userProduct.end_time) > currentDateTime : false; 
        const isPlanType = userProduct.type === 'plan';
        
        if (isPlanType && userProduct.status === 'active') {
          return (
            <div className='d-flex flex-column'>
              {userProduct.next_renewal_on && (
                <span>{'Next Billing Date: '}{moment(userProduct.next_renewal_on).format('Do MMMM YYYY, h:mm:ss A')}</span>
              )}
              {userProduct.next_allocation_date && (
                <span>{'Next Allocation Date: '}{moment(userProduct.next_allocation_date).format('Do MMMM YYYY, h:mm:ss A')}</span>
              )}
            </div>
          );
        }
    
        //expired status 
        if (isPlanType && userProduct.status !== 'active') {
          if (isEndTimeGreater) {
            return null;
          }
      
          if (userProduct.next_allocation_date) {
            return (
              <div className='d-flex flex-column'>
                <span>{'Next Allocation Date: '}{moment(userProduct.next_allocation_date).format('Do MMMM YYYY, h:mm:ss A')}</span>
              </div>
            );
          }
          return null;
        }
    
        return null;
      },
    },
  ]

  return (
    <div className='d-flex flex-column gap-1'>
      <UserSubscriptionsTable
        data={state.userSubscriptions}
        columns={columns}
      />
    </div>
  )
}

export default UserSubscriptions