import React from 'react';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'

import ClipboardCopy from '../../widgets/clipboard_copy';
import AddDailyLimitModal from '../add_daily_limit';
import UpdateDailyLimitModal from '../update_daily_limit'
import RemoveDailyLimit from '../remove_daily_limit'
import AllowedDailyLimitModal from '../allowed_daily_limit'
import AddBonusModel from '../add_bonus';
import ResetBonusModel from '../reset_bonus';
import UpdateRateLimitModal from '../update_api_rate_limit';
import RemoveRateLimitModel from '../remove_api_rate_limit';
// import UpdateOrgAllocateSeatModal from '../updateOrgAllocateSeat';
import * as Helpers from '../../../helpers/helper'
import * as OrgInfoConstants from '../helpers/constant'
import ClickToCopy from '../../widgets/copy_onclick';

import 'react-toggle/style.css';

export const orgEmailFormatter = ({ value }) => {
  return (
    <>
      <div>
        <ClickToCopy text={value} />
      </div>
    </>
  )
}

export const orgPhoneFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </>
  )
}

export const orgWebsiteFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </>
  )
}

export const orgPlanFormatter = ({ value }) => {
  return (
    <>
      {value ?? '-'}
    </>
  )
}



export const timezoneFormatter = ({ value }) => {
  return (
    <>
      <div>
        {value ?? '-'}
      </div>
    </>
  )
}

export const orgRemainingCreditsFormatter = ({ client, value, userData, getUserInfo }) => {
  return (
    <div className="d-flex gap-2 remaining-credits">
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
      <AddBonusModel
        client={client}
        getUserInfo={getUserInfo}
        name={userData.name}
        email={userData.email}
        userData={userData}
      />
      <ResetBonusModel
        client={client}
        getUserInfo={getUserInfo}
        name={userData.name}
        userData={userData}
      />
    </div>
  )
}


export const addMoreSeatsFormatter = ({ userData, /* client, getUserInfo */ }) => {
  return (
    <div className="d-flex gap-2 remaining-credits">
      <span>{userData.usedMemberSeat + ' Occupied' + ' / ' + userData.totalMemberSeat + ' Allocated'}</span>
      {/* <UpdateOrgAllocateSeatModal
        client={client}
        getUserInfo={getUserInfo}
        name={userData.name}
        email={userData.email}
        userData={userData}
      /> */}
    </div>
  )
}

export const signupFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.signup_source && additionalData.signup_method
        ? `${Helpers.capitalizeFirstLetter(additionalData.signup_source)} / ${Helpers.capitalizeFirstLetter(additionalData.signup_method)}`
        : 'Direct / Email'}
    </>
  )
}

export const companyNameFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.organization_name
        ? Helpers.capitalizeFirstLetter(additionalData.organization_name)
        : '-'}
    </>
  )
}

export const userIdFormatter = ({ value }) => {
  return (
    <div className='text-break'>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </div>
  )
}

export const orgIdFormatter = ({ client, value, userData }) => {
  return (
    <div className='d-flex'>
      <div>
        {value ? (
          <ClickToCopy text={value} />
        ) : '-'}
      </div>
      <div>
        {userData.user_type === 'organization' ? (
          <>
            <Link
              to={`${client}/organization_info?org_id=${userData.org_id}`}
              title="org info"
              target="_blank"
              className='ms-1'
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </>
        ) : null}
      </div>
    </div>
  )
}

export const accountStatusFormatter = ({ value, userData }) => {
  return (
    <div className={userData.status === 'active' ? 'text-success' : 'text-danger'}>
      {Helpers.capitalizeFirstLetter(value) ?? '-'}
    </div>
  )
}

export const radarFormatter = ({ userData, isChecked, handleToggleChange }) => {
  return (
    <>
      {userData && userData.user_role === 'owner' ? (
        <Toggle
          checked={isChecked}
          onChange={handleToggleChange}
          disabled={
            userData.status === 'active' &&
              userData.account_status === 'live' &&
              userData.activated_on
              ? false
              : true
          }
        />
      ) : (
        '-'
      )}
    </>
  )
}

// credits and limits tab sections formatters

export const totalRemainingCreditsFormatter = ({ value }) => {
  return (
    <>
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
    </>
  )
}

export const payAsYouGoCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const subscriptionCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const recurCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const apiRateLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        <div className='d-flex'>
          <div className='mx-2'>
            <UpdateRateLimitModal
              data={userData}
              getUserInfo={getUserInfo}
              client={client}
            />
          </div>
          <div>
            <RemoveRateLimitModel
              data={userData}
              getUserInfo={getUserInfo}
              client={client}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const instantEmailVerifyFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const instantEmailFinderFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const dailyLimitFormatter = ({ label }) => {
  return (
    <>
      <p>{label}</p>
      {/* <div>
        {userData ? (
          userData &&
            !userData.limits.allowed_daily_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_limit ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div> */}
    </>
  )

}

export const remainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div className='d-flex gap-2'>
        <p>{(userData.credits.available_daily_limit ?? 'Unlimited').toLocaleString()}</p>
        <div>
          <UpdateDailyLimitModal
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        </div>
      </div>
    </>
  )
}

export const allowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div className='d-flex gap-2'>
        <p>{(userData.limits.allowed_daily_limit ?? 'Unlimited').toLocaleString()}</p>
        <div>
          <AllowedDailyLimitModal
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        </div>
      </div>
    </>
  )
}

// cop formatterrs

export const copDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_verify_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_verify_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )
}

export const copRemainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.credits.available_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <UpdateDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const copAllowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.limits.allowed_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <AllowedDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantPhoneNumberValidatorFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const copTotalAmountPaidFormatter = ({ value }) => {
  return (
    <>
      {value ? Number(value).toLocaleString() : '-'}
    </>
  )
}

export const copLastPurchasedOnFormatter = ({ value }) => {
  return (
    <>
      {value ? moment(value).format('DD-MMM-YYYY @ h:mm:ss a') : '-'}
    </>
  )
}

export const copTotalPaymentCountFormatter = ({ value }) => {
  return (
    <>
      {value ? value : '-'}
    </>
  )
}

// receipt table formatter

export const receiptCreditFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const receiptBillingFormatter = ({ value }) => {
  return (
    <>
      {value ?
        (value === 30 ? 'Monthly' : 'Annually') : '-'}
    </>
  )
}

export const receiptPaymentFormatter = ({ value }) => {
  return (
    <>
      {value}
    </>
  )
}

export const receiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const receiptAmountFormatter = ({ rowData }) => {
  const getAmount = (invoice) => {
    let amount, convertedAmount;

    amount = invoice.amount;
    if (invoice && invoice.currency_exchange_info) {
      convertedAmount = (Math.floor(invoice.currency_exchange_info.final_price * 100) / 100);
      convertedAmount = `${invoice.currency_exchange_info.currency_symbol}${convertedAmount.toLocaleString('en-IN')}`;
    }
    return (
      <td>
        {Number(amount).toLocaleString()} <br />
        {convertedAmount ? convertedAmount : null}
      </td>
    );
  };
  return (
    <div>
      {getAmount(rowData)}
    </div>
  )
}

export const receiptCouponFormatter = ({ rowData }) => {
  const getCoupon = (invoice) => {
    let coupon = '-'
    if (invoice && invoice.discount) {
      coupon =
        <>
          <Link to={'/clearout/coupons/' + invoice.discount.coupon}>
            {invoice.discount.coupon}
          </Link>
          &nbsp;(<small>${invoice.discount.discount_price}</small>)
        </>
    }
    return <td>{coupon}</td>
  }

  return (
    <>
      {getCoupon(rowData)}
    </>
  )
}

export const receiptPurchasedFormatter = ({ value }) => {
  return (
    <small>
      {moment(value).format('DD MMM YY, hh:mm:ss A z')}
    </small>
  )
}

export const receiptTypeFormatter = ({ value }) => {
  return (
    <>
      {Helpers.capitalizeFirstLetter(value)}
    </>
  )
}

export const receiptDescriptionFormatter = ({ rowData, value }) => {
  let val = '-'
  if (value)
    val = value

  if (rowData.type === 'paid')
    val = (
      <div className='d-flex flex-column'>
        <span>{rowData.product_details.display_name}</span>
        <span className='text-muted text-uppercase'>
          <small>
            Payment Gateway: {_.startCase(rowData.account_name || rowData.gateway)}
          </small>
        </span>
      </div>
    )

  return (<>{val}</>)
}

export const receiptPlanIdFormatter = ({ value }) => {
  return (
    <>
      <b><small>{value ? value : '-'}</small></b>
    </>
  )
}

export const receiptPayeeInfoFormatter = ({ value, rowData }) => {
  const getPayeeInfo = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.payer.name.given_name + ' ' + invoice.payment_details.result.payer.name.surname}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.payer.email_address}&nbsp; &nbsp;
            <ClipboardCopy textToCopy={invoice.payment_details.result.payer.email_address} />
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeInfo(rowData) : '-'}
    </>
  )
}

export const receiptPayeeAddressFormatter = ({ value, rowData }) => {
  const getPayeeAddress = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.purchase_units[0].shipping.address.address_line_1}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_1 + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_2}
          </span>
          <span>
            {invoice.payment_details.result.purchase_units[0].shipping.address.country_code + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.postal_code}
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeAddress(rowData) : '-'}
    </>
  )
}

export const invoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, client }) => {
  const apiInvoicePath = OrgInfoConstants.CO_INVOICE_DOWNLOAD_ENDPOINT(client)
  const apiReceiptPath = OrgInfoConstants.CO_RECEIPT_DOWNLOAD_ENDPOINT(client)

  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          APP_API_URL + apiInvoicePath +
          'invoice_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData._id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          APP_API_URL + apiReceiptPath +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData._id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}

export const coReceiptBillingFormatter = ({ rowData, value }) => {
  let billing = '-'

  if (rowData.type === 'paid')
    if (value === null)
      billing = 'One-Time'
    else
      billing = `${value.count} ${value.unit}`

  return (
    <>{billing}</>
  )
}

export const coReceiptPayeeInfoFormatter = ({ value }) => {
  let payeeInfo = '-'

  if (value && value.gateway && value.gateway.email_address) {
    payeeInfo = (
      <>
        <span>{value.gateway.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.email_address} />
        </span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.user_id}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.user_id} />
        </span>
      </>
    )
  } else if (value && value.co && value.co.email_address) {
    payeeInfo = (
      <>
        <span>{value.co.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.co.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.co.email_address} />
        </span>
      </>
    )
  }

  return (
    <>{payeeInfo}</>
  )
}

export const coReceiptPayeeAddressFormatter = ({ value }) => {
  let payeeAddress = '-'

  if (value && value.gateway && value.gateway.billing_address.country_code) {
    payeeAddress = (
      <>
        <span>
          {
            value.gateway.billing_address.address_line_1
              ? value.gateway.billing_address.address_line_1 + ', '
              : null
          }
          {
            value.gateway.billing_address.address_line_2
              ? value.gateway.billing_address.address_line_2 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.admin_area_2
              ? value.gateway.billing_address.admin_area_2 + ', '
              : null
          }
          {
            value.gateway.billing_address.admin_area_1
              ? value.gateway.billing_address.admin_area_1 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.postal_code
              ? value.gateway.billing_address.postal_code + ' - '
              : null
          }
          {
            value.gateway.billing_address.country_code
              ? value.gateway.billing_address.country_code
              : null
          }
        </span>
      </>
    )
  } else if (_.get(value, 'co.billing_address.country.value', null)) {
    payeeAddress = (
      <>
        <span>
          {
            value.co.billing_address.line1
              ? value.co.billing_address.line1 + ', '
              : null
          }
          {
            value.co.billing_address.line2
              ? value.co.billing_address.line2 + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.city
              ? value.co.billing_address.city + ', '
              : null
          }
          {
            value.co.billing_address.state
              ? value.co.billing_address.state + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.postal_code
              ? value.co.billing_address.postal_code + ' - '
              : null
          }
          {
            value.co.billing_address.country.value
              ? value.co.billing_address.country.value
              : null
          }
        </span>
      </>
    )
  }

  return (
    <div className='d-flex flex-column'>
      {payeeAddress}
    </div>
  )
}




















